import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";

// Actions
import {
  downloadExcelReport,
  downloadCampaignPdfReport,
  generateCampaignExcelReport,
} from "../../../actions/campaign-report/CampaignReportActions";

// Constants and Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { RedirectTo } from "../../../urls/PageLinksURL";
import {
  CampaignDemographicStatsStatus,
  ExcelReportStatus,
} from "../../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Page Components
function DownloadReport({
  campaignId,
  campaignTitle,
  isShowDownloadExcelReportButton,
  isMantaraySpecific,
}) {
  //Dispatch
  const dispatch = useDispatch();

  //Selector
  const downloadingXlsReport = useSelector(
    (state) => state.campaignReport.downloadXlsReportLoader
  );

  const downloadingPdfReport = useSelector(
    (state) => state.campaignReport.downloadCampaignPdfReportLoader
  );

  // Loading
  if (downloadingPdfReport || downloadingXlsReport) {
    return <Spinner className="spinner-border mx-3" />;
  }

  // dropdown items
  const dropdownOptions = [
    {
      showOption: isShowDownloadExcelReportButton,
      label: "XLSX",
      onClick: () =>
        dispatch(
          downloadExcelReport(campaignId, campaignTitle, isMantaraySpecific)
        ),
    },
    {
      showOption: true,
      label: "PDF",
      onClick: () =>
        dispatch(
          dispatch(downloadCampaignPdfReport(campaignId, campaignTitle))
        ),
    },
  ];

  return (
    <Dropdown style="mt-0" buttonName="Download Campaign Report">
      <div className="dropdown-menu dropdown-menu-right">
        {dropdownOptions.map((option, index) => {
          const { label, onClick, showOption } = option;
          if (!showOption) {
            return;
          }

          return (
            <button key={index} className="dropdown-item" onClick={onClick}>
              {label}
            </button>
          );
        })}
      </div>
    </Dropdown>
  );
}

function ReportSection({ campaign = {}, isMantaraySpecific = false }) {
  const dispatch = useDispatch();

  // Selector
  const reportGenerating = useSelector(
    (state) => state.campaignReport.generateReportLoading
  );

  const {
    id: campaignId,
    title = "",
    demographicStatisticsStatus,
    excelReportStatus,
    mediaCount,
  } = campaign;
  const isMediaSitesPresent = mediaCount > 0;

  // checking Demographic stats Processed & InProgress
  const isDemographicStatsProcessed =
    demographicStatisticsStatus === CampaignDemographicStatsStatus.PROCESSED;
  const isDemographicStatsInProgress =
    demographicStatisticsStatus === CampaignDemographicStatsStatus.IN_PROGRESS;

  // checking excel report is generated or not
  const isExcelReportGenerated =
    excelReportStatus === ExcelReportStatus.GENERATED;

  const isShowDownloadExcelReportButton =
    isMediaSitesPresent && isExcelReportGenerated;

  useEffect(() => {
    if (isMediaSitesPresent && !isExcelReportGenerated) {
      dispatch(
        generateCampaignExcelReport(campaignId, isDemographicStatsInProgress)
      );
    }
  }, [dispatch, campaignId, excelReportStatus, demographicStatisticsStatus]);

  // Generate Report
  if (reportGenerating) {
    return (
      <AlertMessage
        className="mb-0 py-2"
        spinnerClassName="spinner-border-sm"
        textMessage="Your report is being generated. We will update here once it is generated."
      />
    );
  }

  // graph report page url
  const campaignGraphReportPageUrl =
    RedirectTo.graphReportsCampaignPageUrl.replace(":campaignId", campaignId);

  // Download Report and view graph report
  return (
    <div className="d-flex align-items-center">
      {/* hiding option for mantaray */}
      {!isMantaraySpecific && isDemographicStatsProcessed && (
        <Link to={constructRedirectPath(campaignGraphReportPageUrl)}>
          View Graph Report
        </Link>
      )}

      <DownloadReport
        campaignId={campaignId}
        campaignTitle={title}
        isShowDownloadExcelReportButton={isShowDownloadExcelReportButton}
        isMantaraySpecific={isMantaraySpecific}
      />
    </div>
  );
}

export default ReportSection;
