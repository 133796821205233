export const UserAndRoles = {
  // get org all users
  GET_ORG_ALL_USERS: "GET_ORG_ALL_USERS",
  GET_ORG_ALL_USERS_SUCCESS: "GET_ORG_ALL_USERS_SUCCESS",
  GET_ORG_ALL_USERS_FAILURE: "GET_ORG_ALL_USERS_FAILURE",

  // get org user by id
  GET_ORG_USER_BY_ID: "GET_ORG_USER_BY_ID",
  GET_ORG_USER_BY_ID_SUCCESS: "GET_ORG_USER_BY_ID_SUCCESS",
  GET_ORG_USER_BY_ID_FAILURE: "GET_ORG_USER_BY_ID_FAILURE",

  // disable org user
  DISABLE_ORG_USER: "DISABLE_ORG_USER",
  DISABLE_ORG_USER_SUCCESS: "DISABLE_ORG_USER_SUCCESS",
  DISABLE_ORG_USER_FAILURE: "DISABLE_ORG_USER_FAILURE",

  // enable org user
  ENABLE_ORG_USER: "ENABLE_ORG_USER",
  ENABLE_ORG_USER_SUCCESS: "ENABLE_ORG_USER_SUCCESS",
  ENABLE_ORG_USER_FAILURE: "ENABLE_ORG_USER_FAILURE",

  // create org user
  CREATE_ORG_USER: "CREATE_ORG_USER",
  CREATE_ORG_USER_SUCCESS: "CREATE_ORG_USER_SUCCESS",
  CREATE_ORG_USER_FAILURE: "CREATE_ORG_USER_FAILURE",

  // Update org user
  UPDATE_ORG_USER: "UPDATE_ORG_USER",
  UPDATE_ORG_USER_SUCCESS: "UPDATE_ORG_USER_SUCCESS",
  UPDATE_ORG_USER_FAILURE: "UPDATE_ORG_USER_FAILURE",

  // clear org user state
  CLEAR_ORG_USER: "CLEAR_ORG_USER",

  // get org user stats
  GET_ORG_USER_STATS: "GET_ORG_USER_STATS",
  GET_ORG_USER_STATS_SUCCESS: "GET_ORG_USER_STATS_SUCCESS",
  GET_ORG_USER_STATS_FAILURE: "GET_ORG_USER_STATS_FAILURE",

  // Update org user price settings
  UPDATE_ORG_USER_PRICE_SETTINGS: "UPDATE_ORG_USER_PRICE_SETTINGS",
  UPDATE_ORG_USER_PRICE_SETTINGS_SUCCESS:
    "UPDATE_ORG_USER_PRICE_SETTINGS_SUCCESS",
  UPDATE_ORG_USER_PRICE_SETTINGS_FAILURE:
    "UPDATE_ORG_USER_PRICE_SETTINGS_FAILURE",

  // open PriceSettings Cities Selection Form
  OPEN_PRICE_SETTINGS_CITIES_FORM: "OPEN_PRICE_SETTINGS_CITIES_FORM",
  CLOSE_PRICE_SETTINGS_CITIES_FORM: "CLOSE_PRICE_SETTINGS_CITIES_FORM",
};
