import React from "react";
import { useSelector } from "react-redux";

//Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { constructSingleDateString } from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS, UrlKeys } from "../../../constants/GeneralConstants";
import { getOtsTotal } from "../../../utils/mavinMetrics";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { calculatePercentage } from "../../../common-utils/number-utils/NumberUtils";

// Components
import { getLtsRatingAvg } from "../../../components/campaign-table-row/CampaignRowCells";
import { ProgressBar } from "../../../components/progress-bar/ProgressBar";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Section Components
function ImpressionsCardContent({ tgOtsTotal, tgPercentage, otsTotal }) {
  return (
    <>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-green"></span>
        TG : {formatText(toLocaleString(tgOtsTotal))}
        {/* {`(${tgPercentage}%)`}  */}
      </p>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-blue"></span>
        Total : {formatText(toLocaleString(otsTotal))}
      </p>

      {parseInt(otsTotal) > 0 && (
        <>
          <ProgressBar className="bg-light-green" percentage={tgPercentage} />
          <ProgressBar className="bg-light-blue" percentage={100} />
        </>
      )}
    </>
  );
}

function CampaignPerformance({
  campaignDuration,
  startDate,
  showGenerateExcelReportAlert = false,
}) {
  const excelReportGenerateLoading = useSelector(
    (state) => state.campaignReport.generateCampaignExcelReportLoading
  );

  const showExcelReportAlert =
    showGenerateExcelReportAlert && excelReportGenerateLoading;

  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <div>
        <h4 className="sec-title">Campaign Performance</h4>
        <div className="">
          <p className="mb-0">
            <em className="text-small">
              Estimated for a {campaignDuration} days duration starting{" "}
              <strong>{startDate}</strong>
            </em>
          </p>
          <p className="mt-0 mb-2">
            <em className="text-small">
              For accurate performance details please enter campaign start date
              and duration at a city level or site level.
            </em>
          </p>
        </div>
      </div>

      {/* Generate Excel Report Alert */}
      {showExcelReportAlert && (
        <AlertMessage
          className="mb-0 py-2 pr-4"
          spinnerClassName="spinner-border-sm"
          textMessage={
            <p className="mb-0">
              Excel report data generating. <br /> It can be downloaded once
              generation is done.
            </p>
          }
        />
      )}
    </div>
  );
}

function MavinStats({ statDisplayTitle, statDisplayValue }) {
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h4 className="card-title">{statDisplayTitle}</h4>
          <div className="card-text">{statDisplayValue}</div>
        </div>
      </div>
    </div>
  );
}

/**
 * Page Section
 */
function OverviewSection({
  campaign = {},
  summary = {},
  showGenerateExcelReportAlert = false,
}) {
  const { startTimestamp } = campaign;
  const { duration: campaignDuration, ltsRatingAvg } = summary;
  const { budget = 0, spend = 0 } = campaign;
  const startDate = constructSingleDateString(
    startTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );
  const ltsAvg = getLtsRatingAvg(ltsRatingAvg);

  const { otsTotal, tgOtsTotal } = getOtsTotal(summary) || {};
  const tgPercentage = calculatePercentage(
    Number(tgOtsTotal),
    Number(otsTotal)
  );

  const mavinStats = {
    "No. of Sites": formatText(summary.mediaCount),
    Impressions: (
      <ImpressionsCardContent
        tgOtsTotal={tgOtsTotal}
        otsTotal={otsTotal}
        tgPercentage={tgPercentage}
      />
    ),
    "Avg LTS rating": formatText(ltsAvg),
  };

  const { mantaraySpecific } = UrlKeys;
  const isMantaraySpecific = useUrlKeyCheck(mantaraySpecific);
  const totalBudget = budget ? toLocaleString(budget) : 0;
  const usedBudget = spend ? toLocaleString(Math.floor(spend)) : 0;
  const remainingBudget = budget - spend;
  const remainingBudgetFormatted = remainingBudget
    ? toLocaleString(Math.ceil(remainingBudget))
    : 0;

  // remaining budget className
  const remainingBudgetClassName =
    budget < spend ? "text-danger" : "text-success";

  isMantaraySpecific &&
    Object.assign(mavinStats, {
      Budget: (
        <>
          <p className="mb-1">Total : {totalBudget}</p>
          <p className="mb-1">Used : {usedBudget}</p>
          <p className="mb-0">
            Remaining :{" "}
            <span className={remainingBudgetClassName}>
              {remainingBudgetFormatted}
            </span>
          </p>
        </>
      ),
    });

  return (
    <>
      {/* Campaign Performance */}
      <CampaignPerformance
        campaignDuration={campaignDuration}
        startDate={startDate}
        showGenerateExcelReportAlert={showGenerateExcelReportAlert}
      />

      {/*Stats Card */}
      <div className="row">
        {Object.keys(mavinStats).map((eachKey, index) => (
          <MavinStats
            statDisplayTitle={eachKey}
            statDisplayValue={mavinStats[eachKey]}
            key={index}
          />
        ))}
      </div>
    </>
  );
}

export default OverviewSection;
