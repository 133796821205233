//Constants
import { DefaultPagination } from "../constants/GeneralConstants";

// Utils
import {
  sendGetRequest,
  sendCreateOrUpdateRequest,
  deleteRequest,
  downloadFile,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";

// Urls
import {
  addSellerMediaImagesUrl,
  disableSellerMediaUrl,
  enableSellerMediaUrl,
  getSellerMediaByCityUrl,
  getSellerMediaInfoUrl,
  getSellerMediaUrl,
  getStretchMedia,
  removeSellerMediaImageUrl,
  updateMetricsForSellerMediaUrl,
  updateSellerMediaInfoUrl,
  sellerInventoryUploadUrl,
  downloadSellerInventoryTemplateUrl,
  downloadSellerMediaPdfReportUrl,
} from "../urls/SellerMediaURL";

export async function getStretchMediaDetails(stretchIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const roadStretchIds = stretchIds.join();
  const params = {
    ids: roadStretchIds,
  };

  return sendGetRequest(getStretchMedia, params, true, headerParams);
}

export async function getSellerMedia(
  cityId,
  isOrgMedia,
  orgId = "",
  types,
  pageNo,
  pageSize
) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const params = {
    pn: pageNo,
    ps: pageSize,
    city: cityId,
  };

  if (types) {
    params._type = types;
  }
  if (isOrgMedia) {
    params.isOrgMedia = true;
    params._org = orgId;
  }

  return sendGetRequest(getSellerMediaUrl, params, true, headerParams);
}

export async function getSellerMediaByCityFn(
  cityId,
  sellerId,
  pageNumber,
  pageSizeCount
) {
  const url = getSellerMediaByCityUrl
    .replace(":sellerId", sellerId)
    .replace(":cityId", cityId);

  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSizeCount || DefaultPagination.pageSize,
  };

  return sendGetRequest(url, params, true, headerParams);
}

export function updateMetricsForSellerMediaFn(sellerId, mediaId) {
  const url = updateMetricsForSellerMediaUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);
  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}

// Seller Media Information
export async function getSellerMediaInfoFn(sellerId, mediaId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getSellerMediaInfoUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url, {}, true, headerParams);
}

/**
 * @param {*} mediaInfoBean
 * @returns the body in required format
 */
function constructMediaBean(mediaInfoBean) {
  const { latitude, longitude } = mediaInfoBean;

  const body = {
    title: mediaInfoBean.title,
    type: mediaInfoBean.type,
    litStatusStr: mediaInfoBean.lit,
    height: mediaInfoBean.height,
    width: mediaInfoBean.width,
    elevation: mediaInfoBean.elevation,
    locationStr: latitude + "," + longitude,
    tilt: mediaInfoBean.tilt,
  };
  return body;
}

// Update Seller Media Information
export async function updateSellerMediaInfoFn(
  sellerId,
  mediaId,
  mediaInfoBean
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const body = constructMediaBean(mediaInfoBean);
  const url = updateSellerMediaInfoUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);

  return sendCreateOrUpdateRequest(url, body, true, "PUT", headerParams);
}

// add Seller media images
export async function addSellerMediaImagesFn(sellerId, mediaId, files) {
  const formData = new FormData();
  formData.append("type", "IMAGE");
  Object.values(files).forEach((file) => formData.append("files", file));

  return sendCreateOrUpdateRequest(
    addSellerMediaImagesUrl
      .replace(":sellerId", sellerId)
      .replace(":mediaId", mediaId),
    formData
  );
}

// Delete Seller Media Image
export async function removeSellerMediaImageFn(sellerId, mediaId, image) {
  const params = {
    type: image.type,
    filename: image.filename,
  };

  const url = removeSellerMediaImageUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);

  return deleteRequest(url, {}, true, params);
}

// Enable Seller Media
export async function enableSellerMediaFn(sellerId, mediaId) {
  const url = enableSellerMediaUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}

// Disable Seller Media
export async function disableSellerMediaFn(sellerId, mediaId) {
  const url = disableSellerMediaUrl
    .replace(":sellerId", sellerId)
    .replace(":mediaId", mediaId);

  return sendCreateOrUpdateRequest(url, {}, true, "PUT");
}

// seller Inventory Upload
export async function sellerInventoryUploadFn(sellerId, sellerInventoryBean) {
  const { imageFiles, mediaFile } = sellerInventoryBean;
  const reqHeaders = {
    "Content-Type": "multipart/form-data",
  };

  // Form Data
  const formData = new FormData();
  [...imageFiles].forEach((image) => formData.append("imageFiles", image));
  formData.append("mediaSites", mediaFile);

  const url = sellerInventoryUploadUrl.replace(":sellerId", sellerId);

  return sendCreateOrUpdateRequest(url, formData, true, "POST", reqHeaders);
}

// download template for Seller Inventory template
export async function downloadSellerInventoryTemplateFn() {
  return downloadFile(downloadSellerInventoryTemplateUrl);
}

// download Seller Media Pdf Report
export async function downloadSellerMediaPdfReportFn(
  sellerId,
  mediaId,
  radius
) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  const radiusInKm = radius ? radius / 1000 : 1;

  return downloadFile(
    downloadSellerMediaPdfReportUrl
      .replace(":sellerId", sellerId)
      .replace(":mediaId", mediaId),
    true,
    headerParams,
    { radiusInKm }
  );
}
