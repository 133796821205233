// construct user Price Enabled Cities
export function constructUserPriceEnabledCities(
  regionsName = [],
  priceEnabledCityIds = []
) {
  if (!regionsName || regionsName.length === 0) {
    return [];
  }

  // get cities with id and label
  const filteredCities = regionsName.filter((region) =>
    priceEnabledCityIds.includes(region.id)
  );

  return filteredCities;
}
