import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";

// Actions
import { getMonitoringCampaignSummary } from "../../../actions/campaign-monitoring/MonitoringSettingsActions";
import {
  removeCityFromCampaign,
  updateCampaignTitle,
} from "../../../actions/campaign/CampaignActions";

// Constants and Utils
import { ManageMonitoringCell } from "../../../utils/MonitoringUtils";
import { DispatchFrom } from "../../../constants/GeneralConstants";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import TitleEditor from "../../../components/title-editor/TitleEditor";
import {
  CityNameCell,
  DurationStringCell,
  HamburgerCell,
  MediaCountCell,
  MonitoredMediaCountCell,
} from "../../../components/campaign-table-row/CampaignRowCells";
import ConfirmationForm from "../../../components/confirmation-form/ConfirmationForm";

// CSS
import "../CampaignMonitoring.scss";

// Page-Constant
const tableHeaders = [
  {
    title: {
      displayName: "City",
      className: "col-2 align-middle",
    },
  },

  {
    title: {
      displayName: "No. of Sites",
      className: "col-1 align-middle",
    },
  },
  {
    title: {
      displayName: "No. of Monitored Sites",
      className: "col-2 align-middle",
    },
  },
  {
    title: {
      displayName: "Start and End Date",
      className: "col-2 align-middle",
    },
  },
  {
    title: {
      displayName: "Summary",
      className: "col-2 align-middle",
    },
  },
  {
    title: {
      displayName: "Monitoring",
      className: "col-2 align-middle",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-1 text-right",
    },
  },
];

export function SummaryCell({ isMonitoringEnabled, redirectUrl }) {
  if (!isMonitoringEnabled) {
    return <td className="mb-0 text-muted">Monitoring Not active</td>;
  }

  return (
    <td>
      <Link to={constructRedirectPath(redirectUrl)}>View Summary</Link>
    </td>
  );
}

// Table Row Component
function MonitorCampaignsTableRow({ cityInfo, monitoredMediaCount }) {
  const { id } = useParams();

  const { cityId, isMonitoringEnabled } = cityInfo;

  // link to monitoring city view page
  const monitorCityViewPageUrl = RedirectTo.monitorCityViewPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  const citySummaryPageUrl = RedirectTo.monitorCitySummaryPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  return (
    <tr>
      <CityNameCell
        cityElementStyle="p-3"
        cityInfo={cityInfo}
        redirectUrl={monitorCityViewPageUrl}
      />
      <MediaCountCell mediaCountElementStyle="p-3" cityInfo={cityInfo} />
      <MonitoredMediaCountCell
        mediaCountElementStyle="p-3"
        monitoredMediaCount={monitoredMediaCount}
      />
      <DurationStringCell durationElementStyle="p-3" cityInfo={cityInfo} />
      <SummaryCell
        redirectUrl={citySummaryPageUrl}
        isMonitoringEnabled={isMonitoringEnabled}
      />
      <ManageMonitoringCell
        className="p-3"
        isMonitoringEnabled={isMonitoringEnabled}
        redirectUrl={monitorCityViewPageUrl}
      />
      <HamburgerCell
        hamburgerElementStyle="text-right align-middle"
        cityInfo={cityInfo}
      />
    </tr>
  );
}

// Table-Component
function MonitorCampaignsTable({ infoByCity, monitoredMediaCountByCity }) {
  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table-Header */}
        <TableHeaders
          tableHeaders={tableHeaders}
          headerClass={"thead"}
          requiredSubHeader={false}
        />

        <tbody>
          {/* Table Row */}
          {Object.values(infoByCity).map((cityInfo, i) => (
            <MonitorCampaignsTableRow
              index={i}
              key={cityInfo.cityId}
              cityInfo={cityInfo}
              monitoredMediaCount={monitoredMediaCountByCity[cityInfo.cityId]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Page
 */
function MonitoringCampaignViewPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Selector States
  const loading = useSelector(
    (state) => state.monitoringSettings.campaignSummaryLoading
  );
  const monitoringCampaignSummary =
    useSelector((state) => state.monitoringSettings.campaignSummary) || {};
  const campaign = monitoringCampaignSummary?.campaign;
  const infoByCity = campaign?.infoByCity;
  const monitoredMediaCountByCity =
    monitoringCampaignSummary?.monitoredMediaCountByCity;

  // Title Loading
  const titleLoader = useSelector((state) => state.campaign.titleUpdateLoader);

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );
  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.monitorCampaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.monitorCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  // Dispatch
  useEffect(() => {
    dispatch(getMonitoringCampaignSummary(id));
  }, [dispatch, id]);

  const dispatchFrom = DispatchFrom.campaignMonitoring;
  // Functions to "remove city from existing campaign"
  function removeCity() {
    dispatch(
      removeCityFromCampaign(id, processDataObject.cityId, dispatchFrom)
    );
  }

  // Loading till fetches monitoring-campaign-summary
  if (loading) {
    return <Spinner className="mt-2 spinner-center" />;
  }

  const pageTitle = (
    <TitleEditor
      title={campaign.title}
      onTitleChange={(titleStr) =>
        dispatch(updateCampaignTitle(titleStr, id, dispatchFrom))
      }
      loader={titleLoader}
      inputBoxClass={"rounded-lg"}
      titleClass={"px-0 mb-0 h1"}
    />
  );

  return (
    <>
      {/* Monitoring Sidebar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      <div className="content-wrapper">
        {/* Page-Header */}
        <PageHeader title={pageTitle} />

        {/* Page-Content */}
        <div className="page-content">
          <h4 className="sec-title">{"City highlights"}</h4>
          <MonitorCampaignsTable
            infoByCity={infoByCity}
            monitoredMediaCountByCity={monitoredMediaCountByCity}
          />
        </div>
      </div>

      {/* MODALS */}
      {/* Confirmation-Form */}
      {openConfirmationForm && <ConfirmationForm onClick={removeCity} />}
    </>
  );
}

export default MonitoringCampaignViewPage;
