import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  createOrgUser,
  disableOrgUser,
  enableOrgUser,
  getOrgAllUsersData,
  getOrgUserDataById,
  getOrgUserStatsFn,
  updateOrgUserInfo,
  updateOrgUserPriceSettingsFn,
  updateOrgUserRoles,
} from "../../apis/OrgUserManageAPI";
import { getCampaignSummaryData } from "../../apis/CampaignReportAPI";

// Constants, Utils
import { getErrorMessage } from "../../utils/util";
import { UserAndRoles } from "../../constants/action-constants/org/OrgUserAndRolesActionConstants";
import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../../utils/localstorage";

function* getOrgAllUsers(action) {
  const { pageNumber, pageSize } = action.payload;
  try {
    const orgUsers = yield getOrgAllUsersData(pageNumber, pageSize);
    const { items, pagination } = orgUsers;
    yield put({
      type: UserAndRoles.GET_ORG_ALL_USERS_SUCCESS,
      payload: { orgUsers: items, pagination },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.GET_ORG_ALL_USERS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getOrgUserById(action) {
  try {
    const { userId, storeUserPriceSettings = false } = action.payload;

    // user info api
    const orgUserInfo = yield getOrgUserDataById(userId);

    yield put({
      type: UserAndRoles.GET_ORG_USER_BY_ID_SUCCESS,
      payload: { orgUserInfo, storeUserPriceSettings },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.GET_ORG_USER_BY_ID_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* disableOrgUserById(action) {
  try {
    const { userId, history } = action.payload;
    yield disableOrgUser(userId);
    yield put({
      type: UserAndRoles.DISABLE_ORG_USER_SUCCESS,
    });
    toast.success("Successfully disabled user");
    history.push(constructRedirectPath("/org/user/list"));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.DISABLE_ORG_USER_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* enableOrgUserById(action) {
  try {
    const { userId, history } = action.payload;
    yield enableOrgUser(userId);
    yield put({
      type: UserAndRoles.ENABLE_ORG_USER_SUCCESS,
    });
    toast.success("Successfully enabled user");
    history.push(constructRedirectPath("/org/user/list"));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.ENABLE_ORG_USER_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* createNewOrgUser(action) {
  try {
    const { newUserObj, history } = action.payload;
    yield createOrgUser(newUserObj);
    yield put({
      type: UserAndRoles.CREATE_ORG_USER_SUCCESS,
    });
    toast.success("User created successfully");
    history.push(constructRedirectPath("/org/user/list"));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.CREATE_ORG_USER_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateOrgUser(action) {
  try {
    const { orgUserInfo, id, history } = action.payload;

    yield updateOrgUserInfo(orgUserInfo, id);

    //  TODO ::Remove this once update api is ready
    // yield updateOrgUserRoles(roles, id);

    yield put({
      type: UserAndRoles.UPDATE_ORG_USER_SUCCESS,
    });
    toast.success("User updated successfully");
    history.push(constructRedirectPath("/org/user/list"));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.UPDATE_ORG_USER_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getOrgUserStats() {
  try {
    const orgUserStats = yield getOrgUserStatsFn();

    yield put({
      type: UserAndRoles.GET_ORG_USER_STATS_SUCCESS,
      payload: { orgUserStats },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.GET_ORG_USER_STATS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateOrgUserPriceSettings(action) {
  const { cityIds, campaignId } = action.payload;

  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { userId } = user || {};

  try {
    // update price settings
    yield updateOrgUserPriceSettingsFn(userId, cityIds);

    // user info to get updated "priceEnabledCities"
    const orgUserInfo = yield getOrgUserDataById(userId);
    yield put({
      type: UserAndRoles.GET_ORG_USER_BY_ID_SUCCESS,
      payload: { orgUserInfo, storeUserPriceSettings: true },
    });

    // if price settings updated from a page where excel report option exist,
    // calling campaign summary to get latest data
    if (campaignId) {
      const data = yield getCampaignSummaryData(campaignId);
      yield put({
        type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
        data: data,
      });
    }

    yield put({
      type: UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS_SUCCESS,
    });
    toast.success("User Price Settings updated successfully");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(UserAndRoles.GET_ORG_ALL_USERS, getOrgAllUsers),
    takeLatest(UserAndRoles.GET_ORG_USER_BY_ID, getOrgUserById),
    takeLatest(UserAndRoles.DISABLE_ORG_USER, disableOrgUserById),
    takeLatest(UserAndRoles.ENABLE_ORG_USER, enableOrgUserById),
    takeLatest(UserAndRoles.CREATE_ORG_USER, createNewOrgUser),
    takeLatest(UserAndRoles.UPDATE_ORG_USER, updateOrgUser),
    takeLatest(UserAndRoles.GET_ORG_USER_STATS, getOrgUserStats),
    takeLatest(
      UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS,
      updateOrgUserPriceSettings
    ),
  ]);
}
