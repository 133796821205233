import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  addSellerMediaImages,
  downloadSellerMediaPdfReport,
  getSellerMediaInfo,
} from "../../actions/seller/SellerMediaActions";

// Constants and Utils
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { ImageFileTypes } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../components/spinner/Spinner";
import MediaInfo from "../../mavin/components/media-info/MediaInfo";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MediaSitePerformance from "../../mavin/components/media-site-performance/MediaSitePerformance";
import LocationSummary from "../../mavin/components/location-summary/LocationSummary";
import HourlyTrafficDataSection from "../../mavin/components/hourly-traffic-data/HourlyTrafficDataSection";
import MediaImageWithMap from "../../mavin/components/media-image-with-map/MediaImageWithMap";
import MediaReportDownload from "../../mavin/components/media-report-download/MediaReportDownload";

/**
 * Main Page - Seller Media Details Page
 */
function SellerMediaDetailsPage() {
  const dispatch = useDispatch();
  const { mediaId, sellerId } = useParams();

  // State
  const [radius, setRadius] = useState(1000);

  // Selector
  const media = useSelector((state) => state.sellerMedia.sellerMediaInfo);
  const sellerMediaInfoLoading = useSelector(
    (state) => state.sellerMedia.sellerMediaInfoLoading
  );

  // Seller Media Traffic Data
  const kypTrafficData = useSelector(
    (state) => state.sellerMedia.kypTrafficData
  );

  const sellerMediaFileItems = useSelector(
    (state) => state.sellerMedia.sellerMediaFileItems
  );
  const addSellerMediaImagesLoading = useSelector(
    (state) => state.sellerMedia.addSellerMediaImagesLoading
  );

  const downloadingReportLoading = useSelector(
    (state) => state.sellerMedia.downloadSellerMediaPdfLoading
  );

  // Dispatch
  useEffect(() => {
    dispatch(getSellerMediaInfo(mediaId, sellerId));
  }, [dispatch, mediaId]);

  // function
  async function addSellerMediaImageFn() {
    const files = await selectFile(true, ImageFileTypes);
    dispatch(addSellerMediaImages(sellerId, mediaId, files));
  }

  // Page Loading
  if (sellerMediaInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  if (Object.keys(media).length === 0) {
    return null;
  }

  const { latitude, longitude, title } = media || {};

  // functions
  function handleSellerMediaPdfDownload() {
    dispatch(downloadSellerMediaPdfReport(sellerId, mediaId, radius, title));
  }

  const pageActions = (
    <MediaReportDownload
      loading={downloadingReportLoading}
      onClick={handleSellerMediaPdfDownload}
    />
  );

  return (
    <div className="content-wrapper media-page" id="cont-seller-media-details">
      {/* Page Header */}
      <PageHeader title={"Media Report"} actions={pageActions} shadow={true} />

      <div className="page-content">
        {/* Media info  */}
        <MediaInfo media={media} titleClassName={"h2"} />
        <hr className="divider"></hr>

        {/* Site performance */}
        <MediaSitePerformance
          media={media}
          title={"Site Performance"}
          className="py-3"
        />
        <hr className="divider"></hr>

        {/* Media image and map view*/}
        <MediaImageWithMap
          media={media}
          fileItems={sellerMediaFileItems}
          loading={sellerMediaInfoLoading || addSellerMediaImagesLoading}
          onClick={addSellerMediaImageFn}
        />

        {/* Traffic Data */}
        <HourlyTrafficDataSection
          media={media}
          kypTrafficData={kypTrafficData}
        />

        {/* Seller Media Location Summary */}
        {latitude && longitude && (
          <LocationSummary
            latitude={latitude}
            longitude={longitude}
            radius={radius}
            setRadius={setRadius}
          />
        )}
      </div>
    </div>
  );
}

export default SellerMediaDetailsPage;
