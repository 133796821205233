// Utils
import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";

// Urls
import {
  addOrgMediaImagesUrl,
  downloadOrgMediaPdfReportUrl,
  getOrgMediaInfoUrl,
} from "../urls/OrgMediaURL";

// org Media Information
export async function getOrgMediaInfoFn(orgId, mediaId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = getOrgMediaInfoUrl
    .replace(":orgId", orgId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url, {}, true, headerParams);
}

// add Org media images
export async function addOrgMediaImagesFn(orgId, mediaId, files) {
  const formData = new FormData();
  formData.append("type", "IMAGE");
  Object.values(files).forEach((file) => formData.append("files", file));

  return sendCreateOrUpdateRequest(
    addOrgMediaImagesUrl.replace(":orgId", orgId).replace(":mediaId", mediaId),
    formData
  );
}

// download Org Media Pdf Report
export async function downloadOrgMediaPdfReportFn(orgId, mediaId, radius) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  const radiusInKm = radius ? radius / 1000 : 1;

  return downloadFile(
    downloadOrgMediaPdfReportUrl
      .replace(":orgId", orgId)
      .replace(":mediaId", mediaId),
    true,
    headerParams,
    { radiusInKm }
  );
}
