import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import { cancelAppliedFilters } from "../../../actions/filter/FilterActions";
import {
  cancelSiteMonitoring,
  createSetupMonitoring,
  getMonitoringCampaignSummary,
  getMonitoringCitySummary,
  openSetupMonitoringForm,
} from "../../../actions/campaign-monitoring/MonitoringSettingsActions";

// Constants and Utils
import { FormDataTargets, PageSize } from "../../../constants/GeneralConstants";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import { Dropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";
import Filter from "../../../components/filter/Filter";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import MediaSetupMonitoringForm from "../../../components/media-setup-monitoring-form/MediaSetupMonitoringForm";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Pages and Sections
import MonitoringCampaignCityViewTable from "./MonitoringCampaignCityViewTable";

// CSS
import "../CampaignMonitoring.scss";

// Page Components
function PageHeaderSection({ campaign, citySelected }) {
  const { id, cityId } = useParams();
  const history = useHistory();

  // route-change on excel-upload
  const routeChange = () => {
    history.push({
      pathname: constructRedirectPath(RedirectTo.monitorCampaignCreatePageUrl),
      state: { campaignId: id, cityId: cityId },
    });
  };

  const pageTitle = campaign.title + " :: " + citySelected.cityName;
  const PageActions = (
    <button className="btn btn-primary" onClick={routeChange}>
      {"Upload Sites"}
    </button>
  );

  return <PageHeader title={pageTitle} actions={PageActions} />;
}

function MonitorSelectAction({ cancelSiteMonitoringFunction }) {
  const dispatch = useDispatch();
  const createMonitoringLoading = useSelector(
    (state) => state.monitoringSettings.setupMonitoringLoading
  );
  const cancelSiteMonitoringLoading = useSelector(
    (state) => state.monitoringSettings.cancelSiteMonitoringLoading
  );

  function openSetupMonitoringFormFunction() {
    dispatch(openSetupMonitoringForm());
  }

  return (
    <div className="d-flex mt-1">
      <Dropdown
        buttonName="Select action"
        style="btn btn-outline-primary m-0 mr-3"
      >
        <div className="dropdown-menu dropdown-menu-left">
          <button
            className="dropdown-item"
            type="button"
            data-toggle="modal"
            data-target={`#${FormDataTargets.setupMonitoringForm}`}
            onClick={openSetupMonitoringFormFunction}
          >
            {"Setup monitoring"}
          </button>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => cancelSiteMonitoringFunction()}
          >
            {"Cancel monitoring"}
          </button>
        </div>
      </Dropdown>

      {/* Loading */}
      {(createMonitoringLoading || cancelSiteMonitoringLoading) && (
        <AlertMessage
          className="mb-0 py-1"
          spinnerClassName="spinner-border-sm"
          textMessage={"Please Wait.."}
        />
      )}
    </div>
  );
}

/**
 * Main Page
 */
function MonitoringCampaignCityViewPage() {
  const { id, cityId } = useParams();

  // Selector state
  //  monitoring city-summary
  const citySummaryLoading = useSelector(
    (state) => state.monitoringSettings.monitoringCitySummaryLoading
  );
  const monitoringCitySummary =
    useSelector((state) => state.monitoringSettings.monitoringCitySummary) ||
    {};
  const citySummaryItems = monitoringCitySummary.items || [];
  const pagination = monitoringCitySummary.pagination || {};

  // monitoring campaign summary
  // for "total MediaCount" and "CampaignTitle"
  const campaignSummaryLoading = useSelector(
    (state) => state.monitoringSettings.campaignSummaryLoading
  );
  const monitoringCampaignSummary =
    useSelector((state) => state.monitoringSettings.campaignSummary) || {};
  const campaign = monitoringCampaignSummary?.campaign;
  const citySummary = campaign?.infoByCity?.[cityId] || {};
  const citySelected = {
    cityId: cityId,
    cityName: citySummary.cityName || cityId,
  };

  // for Setup-Monitoring Popup
  const openSetupMonitoringForm = useSelector(
    (state) => state.monitoringSettings.openModal
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.monitorCampaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.monitorCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const pageNumber = 1,
    pageSizeCount = PageSize.MonitoringCitySummary;

  // Selected MediaIds( From Checkbox-multiSelect ) For SetupMonitoring (Array of MediaIdsCSV)
  const selectedSitesObject = useSelector(
    (state) => state.monitoringSettings.selectedSites
  );
  const identifiersKeys = Object.keys(selectedSitesObject);
  const mediaIds = identifiersKeys.filter((id) => selectedSitesObject[id]);

  // Edit Site Monitoring ( single select)
  const monitoringMediaId = useSelector(
    (state) => state.monitoringSettings.monitoringMediaId
  );

  const monitoringEnabledIds = useSelector(
    (state) => state.monitoringSettings.monitoringEnabledIds
  );

  // Final mediaIds or mediaId for Create/edit setup-monitoring-settings
  const finalMediaIds = monitoringMediaId ? [monitoringMediaId] : mediaIds;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMonitoringCampaignSummary(id));
    dispatch(getMonitoringCitySummary(id, cityId, pageNumber, pageSizeCount));
    dispatch(cancelAppliedFilters());
  }, [dispatch, id, cityId, pageSizeCount]);

  // Functions
  function loadCityMedia(pageNumber, pageSizeCount) {
    dispatch(
      getMonitoringCitySummary(id, cityId, pageNumber, pageSizeCount, "", true)
    );
  }

  // Create Monitoring Settings Function
  function onSubmitMonitoringSettings(inputFieldBean) {
    dispatch(createSetupMonitoring(inputFieldBean, id, cityId, finalMediaIds));
  }

  function cancelSiteMonitoringFunction() {
    const monSettingIds =
      mediaIds.length > 0
        ? mediaIds.filter((id) => monitoringEnabledIds.includes(id))
        : monitoringEnabledIds;
    if (monSettingIds.length > 0) {
      dispatch(cancelSiteMonitoring(id, cityId, monSettingIds));
    }
  }

  // Page-loading
  if (citySummaryLoading || campaignSummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Page-Content */}
      <div className="content-wrapper">
        {/* Page-Header */}
        <PageHeaderSection campaign={campaign} citySelected={citySelected} />

        <div className="page-content">
          {/* Action and Filter Section */}
          <div className="d-flex justify-content-between">
            <MonitorSelectAction
              cancelSiteMonitoringFunction={cancelSiteMonitoringFunction}
            />
            <Filter onFiltersApplied={loadCityMedia} pageSize={pageSizeCount} />
          </div>

          {/* monitoring city summary table */}
          <MonitoringCampaignCityViewTable
            citySummaryItems={citySummaryItems}
            pagination={pagination}
            loadCityMedia={loadCityMedia}
          />
        </div>
      </div>

      {/* modals */}
      {openSetupMonitoringForm && (
        <MediaSetupMonitoringForm onFormSubmit={onSubmitMonitoringSettings} />
      )}
    </>
  );
}

export default MonitoringCampaignCityViewPage;
