// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Component
import HelpSidebar from "./HelpSidebar";
import PageHeader from "../../mavin/components/page-header/PageHeader";

// Page Constants
const videoDetailsConstants = [
  {
    label: "OTS Tool",
    videoUrl: "https://youtu.be/rudg4fG2hU0",
    videoId: "rudg4fG2hU0",
  },
  {
    label: "LTS Tool",
    videoUrl: "https://youtu.be/BQe4uQFXDRo",
    videoId: "BQe4uQFXDRo",
  },
  {
    label: "Site Analyzer Tool",
    videoUrl: "https://youtu.be/RG8CkZyz8Sg",
    videoId: "RG8CkZyz8Sg",
  },
  {
    label: "Route Analysis Tool",
    videoUrl: "https://youtu.be/hudkpsS9PZQ",
    videoId: "hudkpsS9PZQ",
  },
  {
    label: "Demographics",
    videoUrl: "https://youtu.be/1GXyoBvL4Vo",
    videoId: "1GXyoBvL4Vo",
  },
  {
    label: "Road Stretches",
    videoUrl: "https://youtu.be/6BlwcgBOFdI",
    videoId: "6BlwcgBOFdI",
  },
  {
    label: "Target Group : Creation",
    videoUrl: "https://youtu.be/wcgZZluEvQE",
    videoId: "wcgZZluEvQE",
  },
  {
    label: "Target Group : Populate",
    videoUrl: "https://youtu.be/TpfSbBVK0aM",
    videoId: "TpfSbBVK0aM",
  },
  {
    label: "Boundaries",
    videoUrl: "https://youtu.be/SOvwHGT6U9Y",
    videoId: "SOvwHGT6U9Y",
  },
  {
    label: "Point Of Interest : Populate",
    videoUrl: "https://youtu.be/CHBQGbF2zNY",
    videoId: "CHBQGbF2zNY",
  },
  {
    label: "Data Layer : Create and Generation",
    videoUrl: "https://youtu.be/NkfHnsrmD6Y",
    videoId: "NkfHnsrmD6Y",
  },
];

// Video Cards
function VideoCardsSection() {
  return (
    <div className="row help-videos">
      {videoDetailsConstants.map((video, i) => {
        const { label = "", videoId = "" } = video;

        return (
          <div key={i} className="col-4">
            <div className="col px-0 card bg-alt">
              <iframe
                src={`https://www.youtube.com/embed/${videoId}?rel=0`}
                frameBorder="0"
                className="video-content"
                allow="autoplay; encrypted-media"
                allowFullScreen={true}
                title={label}
              />
            </div>
            <h4 className="mt-2">{label}</h4>
          </div>
        );
      })}
    </div>
  );
}

/**
 * Videos Help Page
 */
export default function VideosHelpPage() {
  const pageTitle = "Tutorial Videos";

  return (
    <>
      <HelpSidebar activePageUrl={RedirectTo.helpVideosPageUrl} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />
        <div className="page-content">
          <VideoCardsSection />
        </div>
      </div>
    </>
  );
}
