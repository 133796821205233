import { Link } from "react-router-dom";

// Constant and Utils
import {
  constructDateString,
  getDifferenceInDays,
} from "../../../common-utils/date-utils/DateUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import {
  toLocaleString,
  formatText,
} from "../../../common-utils/string-utils/StringUtils";
import { CampaignState } from "./planningConstants";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";

//Components
import { CityRedirect } from "./CampaignRedirect";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import TableHeaders from "../../../components/table/TableHeaders";

// Section Constants
const CityDetailsHeaders = [
  {
    title: {
      displayName: "City",
      className: "",
    },
    subTitle: {
      displayName: "Status",
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "No. of Stretches",
      className: "align-top text-right",
    },
  },
  {
    title: {
      displayName: "No. of Sites",
      className: "align-top text-right",
    },
  },
  {
    title: {
      displayName: "Start and End Date",
      className: "text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text text-right",
    },
  },
  {
    title: {
      displayName: "Est Target Imp",
      className: "text-right align-top",
    },
  },
  {
    title: {
      displayName: "Est General Imp",
      className: "text-right align-top",
    },
  },
  {
    title: {
      displayName: "",
      className: "text-right",
    },
  },
];

// Section Components
function CityName({ basicCityDetails, campaignId, state }) {
  if (!basicCityDetails) {
    return null;
  }
  const { cityName = "--", cityId } = basicCityDetails;

  return (
    <td>
      <CityRedirect
        className="mb-0"
        campaignId={campaignId}
        cityId={cityId}
        state={state}
        displayText={cityName}
      />
    </td>
  );
}

function StretchCount({ cityInfo }) {
  if (!cityInfo) {
    return null;
  }
  const { roadStretchOtsMap } = cityInfo;
  const stretchCount = Object.keys(roadStretchOtsMap).length;
  return (
    <td className="text-right">
      <b>{stretchCount}</b>
    </td>
  );
}

function SitesCount({ basicCityDetails }) {
  const mediaCount = basicCityDetails?.mediaCount || "0";

  return (
    <td className="text-right">
      <b>{mediaCount}</b>
    </td>
  );
}

function Duration({ cityInfo }) {
  const { startTimestamp, endTimestamp } = cityInfo;
  const diff = getDifferenceInDays(startTimestamp, endTimestamp);
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  return (
    <td className="text-right">
      <div className="text-right">
        {dateString}
        <span className="sub-text">{`${diff} days`}</span>
      </div>
    </td>
  );
}

function EstTgImp({ cityInfo }) {
  const { totalTargetOts, totalTargetOtsLit } = cityInfo;
  const estTargetImp = totalTargetOtsLit ?? totalTargetOts;

  return (
    <td className="text-right">
      <p className="mb-0">{formatText(toLocaleString(estTargetImp))}</p>
    </td>
  );
}

function EstGeneralImp({ cityInfo }) {
  const { totalGenericOts, totalGenericOtsLit } = cityInfo;
  const estGenericImp = totalGenericOtsLit ?? totalGenericOts;

  return (
    <td className="text-right">
      <p className="mb-0">{formatText(toLocaleString(estGenericImp))}</p>
    </td>
  );
}

function ContinuePlanning({ cityInfo }) {
  const { campaignId, cityId } = cityInfo;

  return (
    <td className="text-right">
      <div>
        <Link
          to={constructRedirectPath(
            `/campaign/${campaignId}/planning?_city=${cityId}`
          )}
          className="shadow-none"
        >
          {"Continue Planning"}
        </Link>
      </div>
    </td>
  );
}

function CityDetailsRow({ cityInfo, basicCityDetails, campaignId, state }) {
  const EnableContinuePlanning = isAgencySpecific(ContinuePlanning);
  return (
    <tr>
      <CityName
        basicCityDetails={basicCityDetails}
        campaignId={campaignId}
        state={state}
      />
      <StretchCount cityInfo={cityInfo} />
      <SitesCount basicCityDetails={basicCityDetails} />
      <Duration cityInfo={cityInfo} />
      <EstTgImp cityInfo={cityInfo} />
      <EstGeneralImp cityInfo={cityInfo} />
      {(CampaignState.REVIEW === state || CampaignState.DRAFT === state) &&
        EnableContinuePlanning && (
          <EnableContinuePlanning cityInfo={cityInfo} />
        )}
    </tr>
  );
}

export default function CityDetailsTable({ campaignPlanOverview, campaign }) {
  const { id, infoByCity = {} } = campaign;

  return (
    <div className="mt-5">
      <h4>{"City Highlights"}</h4>
      <div className="table-responsive mt-3">
        <table className="table table-media">
          <TableHeaders
            tableHeaders={CityDetailsHeaders}
            headerClass={"thead"}
          />
          <tbody>
            {Object.values(campaignPlanOverview).map((cityInfo) => (
              <CityDetailsRow
                key={cityInfo.cityId}
                cityInfo={cityInfo}
                basicCityDetails={infoByCity[cityInfo.cityId]}
                campaignId={id}
                state={campaign.planStatus}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
