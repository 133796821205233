export const getStretchMedia = "/api/v3/inventory/media/search/road_stretch";
export const getSellerMediaUrl = "/api/v3/inventory/media/search/city";

// mavin seller media
export const getSellerMediaByCityUrl =
  "/api/v3/seller/:sellerId/media/search/city?city=:cityId";
export const updateMetricsForSellerMediaUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/mavin?_f=true";
export const getSellerMediaInfoUrl = "/api/v3/seller/:sellerId/media/:mediaId";
export const updateSellerMediaInfoUrl =
  "/api/v3/seller/:sellerId/media/:mediaId";
export const addSellerMediaImagesUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/files";
export const removeSellerMediaImageUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/file";
export const enableSellerMediaUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/enable";
export const disableSellerMediaUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/disable";
export const sellerInventoryUploadUrl = "/api/v3/seller/:sellerId/media/upload";
export const downloadSellerInventoryTemplateUrl =
  "/api/v3/seller/media/template";
export const downloadSellerMediaPdfReportUrl =
  "/api/v3/seller/:sellerId/media/:mediaId/export/pdf";
