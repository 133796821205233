import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getRegionDataByCity } from "../../../actions/regions/RegionActions";

// Utils and Constants
import { constructRadarChartData } from "../../../utils/ChartUtils";
import {
  ArAC_ratings,
  CityPopulation,
  MediaFrequency,
  MediaReach,
  MediaTgFrequency,
  MonthlyImpressions,
  OtherDetails,
} from "./SitePerformanceUtils";
import { getMediaLtsRating, getMediaOts } from "../../../utils/mavinMetrics";

// Components
import { RadarChart } from "../../../components/charts/Charts";

/**
 * Media Site Performance
 */
function MediaSitePerformance({ media, title = "", className = "" }) {
  const dispatch = useDispatch();

  // Selector
  const { regionDataIndexes = {}, population } = useSelector(
    (state) => state.region.regionData
  );
  const {
    cityId,
    ltsSummary = {},
    reachFrequency = {},
    dwellTimeSummary = {},
    otsSummary = {},
  } = media;

  // OTS , LTS, Duration
  const { ots, tgOts } = getMediaOts(media) || {};
  const ltsRating = getMediaLtsRating(ltsSummary);
  const { durationDays } = otsSummary || {};

  useEffect(() => {
    if (cityId) {
      dispatch(getRegionDataByCity(cityId));
    }
  }, [dispatch, cityId]);

  // Chart data
  const chartData = constructRadarChartData(ltsSummary, regionDataIndexes);

  return (
    <div className={`row ${className}`}>
      <div className="col-9">
        {title && <h3 className="mb-3">{title}</h3>}

        <div className="row">
          {/* Monthly impression */}
          <MonthlyImpressions
            impressions={{ ots, tgOts }}
            duration={durationDays}
          />

          {/* Reach, population and Frequency */}
          <CityPopulation cityPopulation={population} />
          <MediaReach
            reachFrequency={reachFrequency}
            cityPopulation={population}
          />
          <MediaFrequency reachFrequency={reachFrequency} />
          <MediaTgFrequency reachFrequency={reachFrequency} />
          <OtherDetails
            reachFrequency={reachFrequency}
            dwellTimeSummary={dwellTimeSummary}
            ltsRating={ltsRating}
          />
          <ArAC_ratings ltsSummary={ltsSummary} />
        </div>
      </div>

      {/* Radar Chart */}
      <div className="col-3">
        <RadarChart data={chartData} height={300} />
      </div>
    </div>
  );
}

export default MediaSitePerformance;
