// Theme related imports

/**
 * Pre defined APP Themes.
 */
const Themes = {
  default: {
    id: "default",
    name: "MAVIN",
    custom: false,
  },
  prooh: {
    id: "prooh",
    name: "PrOOH",
    custom: true,
  },
  rapport: {
    id: "rapport",
    name: "Factor",
    custom: true,
  },
  moms: {
    id: "moms",
    name: "MOMS",
    custom: true,
  },
  bmeg: {
    id: "bmeg",
    name: "BMeG",
    custom: true,
  },
  prmadv: {
    id: "prmadv",
    name: "PRMADV",
    custom: true,
  },
  mantaray: {
    id: "mantaray",
    name: "Mantaray",
    custom: true,
  },
  advision: {
    id: "advision",
    name: "Advision",
    custom: true,
  },
  patrika: {
    id: "patrika",
    name: "Patrika",
    custom: true,
  },
  excellentPublicity: {
    id: "excellentPublicity",
    name: "ExcellentPublicity",
    custom: true,
  },
};

/**
 * App theme selector
 */
class AppThemes {
  constructor() {}

  // Static Methods
  // --------------------------------------------------------------------------

  /**
   * Get the deployed theme details of the Application.
   */
  static getTheme = () => {
    // Theme ID from env
    const themeId = process.env.REACT_APP_ORG_APP_THEME;

    // Get the Theme from existing defs
    const theme = Themes[themeId];
    if (theme) {
      return theme;
    }

    // Return default
    return Themes.default;
  };
}

export default AppThemes;
