import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import {
  getClosestRoadSegments,
  resetClosestRoadSegments,
} from "../../../actions/org/OrgRoadStretchActions";
import {
  clearSegmentOtsInfo,
  getSegmentOtsAndReachInfo,
  clearSegmentOtsAndReachInfo,
} from "../../../actions/mavin-tools/OtsToolActions";
import {
  clearTgInfo,
  getTargetGroups,
  getTgInfo,
} from "../../../actions/org/OrgTargetGroupActions";
import { closeTargetGroupForm } from "../../../prooh/actions/campaign-planning/TargetGroupFormActions";

// Utils and Constants
import {
  LocationSearchInput,
  MapViewWithPindropAndRoadSelect,
  RouteIdSection,
  SelectTgButton,
} from "../ToolsUtils";
import { NoSegmentsMessageSection } from "../../org/OrgUtils";
import { constructLineChartData } from "../../../utils/ChartUtils";
import { defaultPagination } from "../../../constants/UrlConstants";
import { RegionsData } from "./RegionsDataConstants";
import { DurationOptions } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { ButtonWithLoader } from "../../../mavin/components/button/Button";
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";
import ProbabilitySplitOtsTable from "./ProbabilitySplitOtsTable";
import SegmentOtsSplittedTable from "./SegmentOtsSplittedTable";
import TargetGroupForm from "../../../prooh/pages/campaign-planning/TargetGroupForm";
import { LineChart } from "../../../components/charts/Charts";

// function
function populationGenderSplitPercentageOfCity(cityId) {
  return RegionsData.find((eachCitydata) => cityId === eachCitydata.ID);
}

// Page Components
function HourlyTrafficSection({ selectedSegment }) {
  const { avgSpeedProfile, maxSpeedProfile, minSpeedProfile } = selectedSegment;
  const trafficData = {
    hour_avg: avgSpeedProfile,
    hour_max: maxSpeedProfile,
    hour_min: minSpeedProfile,
  };

  // Chart Data
  const chartTrafficData = constructLineChartData(trafficData);

  //if chart traffic data is available
  if (chartTrafficData.length > 0) {
    return (
      <>
        <h4 className="mt-4">Hourly Traffic Profile</h4>
        <LineChart data={chartTrafficData} />
      </>
    );
  }

  return (
    <h5 className=" mt-3 text-center font-italic">
      Hourly Traffic Profile is not available
    </h5>
  );
}

function LitStatusSelector({ isMediaLit, setIsMediaLit = () => {} }) {
  const litStatusData = [
    { label: "Lit", id: "lit", checked: isMediaLit },
    { label: "Not Lit", id: "notLit", checked: !isMediaLit },
  ];

  function handleOnChange(id) {
    if (id === "lit") {
      setIsMediaLit(true);
      return;
    }
    setIsMediaLit(false);
  }

  return (
    <div className="d-flex align-items-center">
      <b className="d-block mr-3">{"Lit Status:"}</b>

      <div className="d-flex align-items-center justify-content-center">
        {litStatusData.map((data) => {
          const { label, id, checked } = data;
          return (
            <>
              <input
                type="radio"
                id={id}
                onChange={() => handleOnChange(id)}
                name="flexRadioDefault"
                checked={checked}
              />
              <label className="m-0 pl-1 mr-4" htmlFor={id}>
                {label}
              </label>
            </>
          );
        })}
      </div>
    </div>
  );
}

/**
 * Page :Ots Rf Tool Page
 */
export default function OtsRfToolPage() {
  const dispatch = useDispatch();

  // State
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState(null);
  const [isMediaLit, setIsMediaLit] = useState(false);

  const { id: segmentId, cityId } = selectedSegment;

  // all closest road-segments
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // for "TG setup-Form"
  const openTgSetupForm = useSelector(
    (state) => state.targetGroupFormModal.openModal
  );

  // Selected Tg Info
  const selectedTgInfo = useSelector((state) => state.orgTargetGroup.tgInfo);
  const targetGroupId = selectedTgInfo?.targetGroup?.id || "";

  // segment Ots And Reach Info
  const segmentOtsAndReachInfo = useSelector(
    (state) => state.otsTool.segmentOtsAndReachInfo
  );

  const segmentOtsAndReachInfoLoading = useSelector(
    (state) => state.otsTool.segmentOtsAndReachInfoLoading
  );

  // feeder roads,ots and reach information
  const { feederRoads = {}, metrics = {} } = segmentOtsAndReachInfo;

  // gender split percentage
  const populationGenderSplitPercentage =
    populationGenderSplitPercentageOfCity(cityId);

  // DatePicker style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "col-2 px-0",
  };

  // Pagination
  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Reset Ots info
  useEffect(() => {
    setSelectedSegment({});
    setDuration(null);
    setStartDate("");
    dispatch(resetClosestRoadSegments());
    dispatch(clearTgInfo());
    dispatch(closeTargetGroupForm());
    dispatch(clearSegmentOtsAndReachInfo());
  }, [dispatch, locationStr]);

  useEffect(() => {
    dispatch(getTargetGroups(true, "", pageNo, pageSize));
  }, [dispatch]);

  useEffect(() => {
    // showing toaster to select road segment
    if (roadSegments.length > 0) {
      toast.info("Please select the Road to get Ots Split");
    }
    // when ever location change we are clearing previous closest road segment
    // and fetch new closest road segment. so length is changing
    // if the roadSegments length change useEffect will get trigger and shows toaster message
  }, [roadSegments.length]);

  // Functions
  // This call-back function is used to pick the lat-lng and
  // fetch all closest road-segments
  function getCoordinatesFromPinDrop(locationData) {
    const coordinateString = `${locationData.lat},${locationData.lng}`;
    setLocationStr(coordinateString);
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(getClosestRoadSegments(coordinateString, true));
  }

  function onPolylineClick(e, eachSegment) {
    setSelectedSegment({});
    setDuration(null);
    dispatch(clearSegmentOtsInfo());
    dispatch(clearSegmentOtsAndReachInfo());
    setSelectedSegment(eachSegment);
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  // Submit TG Function
  function tgSubmitFn(tgId) {
    // clear the previous tgInfo
    dispatch(clearTgInfo());

    // clearing previous ots
    dispatch(clearSegmentOtsAndReachInfo());

    if (tgId) {
      // get tgInfo
      dispatch(getTgInfo(tgId));
    }
  }

  // function to get Tg Specific Ots And Reach, Frequency For Segment
  function getTgSpecificOtsAndReachFrequencyForSegmentsFn() {
    // Converting "startDate" into Required format
    const startDateTimestamp = Date.parse(startDate);

    if (!targetGroupId) {
      toast.error("Please select Target Group");
      return;
    }

    dispatch(
      getSegmentOtsAndReachInfo(
        segmentId,
        targetGroupId,
        startDateTimestamp,
        duration,
        isMediaLit
      )
    );
  }

  return (
    <>
      <div className="content-wrapper map-content-wrapper">
        {/* Map Left Section */}
        <div className="map-layout-left">
          <PageHeader title={"Impressions (OTS)"} shadow={false} />
          <hr className="divider my-3 row"></hr>

          {/* Left Content */}
          <LocationSearchInput
            locationStr={locationStr}
            setLocationStr={setLocationStr}
            setCoordinate={setCoordinate}
          />

          {/* No RoadSegments Message */}
          <NoSegmentsMessageSection
            roadSegments={roadSegments}
            locationStr={locationStr}
          />

          {/* Selected Segment Info Section */}
          {Object.keys(selectedSegment).length > 0 && (
            <>
              <div className="d-flex justify-content-between align-items-center py-3">
                {/* Route ID */}
                <RouteIdSection id={segmentId} />

                {/* select TG */}
                <SelectTgButton />

                {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
                <BootstrapDateRangePicker
                  isSingleDatePicker={true}
                  initialStartDate={startDate}
                  onApplyDates={setStartDate}
                  placeHolder={"Select Date"}
                  styleObject={styleObject}
                />

                {/* Duration Selector */}
                <DurationSelector
                  items={DurationOptions}
                  onItemSelect={setDuration}
                  startDate={startDate}
                  buttonClassName="shadow-none col px-2"
                />
              </div>
              <div className="d-flex justify-content-between py-2">
                <LitStatusSelector
                  isMediaLit={isMediaLit}
                  setIsMediaLit={setIsMediaLit}
                />

                {/* Submit Button */}
                <ButtonWithLoader
                  displayContent={"Get OTS Split"}
                  onClickFunction={
                    getTgSpecificOtsAndReachFrequencyForSegmentsFn
                  }
                  loader={segmentOtsAndReachInfoLoading}
                  isDisabled={
                    segmentOtsAndReachInfoLoading || !startDate || !duration
                  }
                />
              </div>
              <hr className="divider my-0 row"></hr>
            </>
          )}

          {/* Ots split Table */}
          {Object.keys(segmentOtsAndReachInfo).length > 0 && (
            <>
              <SegmentOtsSplittedTable
                segmentOtsAndReachInfo={metrics}
                duration={duration}
                populationGenderSplitPercentage={
                  populationGenderSplitPercentage
                }
              />

              <ProbabilitySplitOtsTable feederRoads={feederRoads} />
              <HourlyTrafficSection selectedSegment={selectedSegment} />
            </>
          )}
        </div>

        {/* MapView Section */}
        <MapViewWithPindropAndRoadSelect
          coordinate={coordinate}
          selectedSegment={selectedSegment}
          getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
          onPolylineClick={onPolylineClick}
        />
      </div>

      {/* Modals */}
      {/* TG-selection-form */}
      {openTgSetupForm && (
        <TargetGroupForm
          segmentIds={[selectedSegment.id]}
          dispatchFn={tgSubmitFn}
        />
      )}
    </>
  );
}
