import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  getSegmentOtsAndReachInfoFn,
  getSegmentOtsInfoFn,
} from "../../apis/mavin-tools/OtsToolAPI";

// Utils and Constants
import { Ots } from "../../constants/action-constants/mavin-tools/OtsActionConstants";
import { getErrorMessage } from "../../utils/util";

// By Road Segment Id
// ---------------------------------------------------
export function* getSegmentOtsInfo(action) {
  const { id, isOneWaySegment, lanesCount, type } = action.payload;
  try {
    // full ots info of segment
    const segmentOtsInfo = yield getSegmentOtsInfoFn(
      id,
      isOneWaySegment,
      lanesCount,
      type
    );

    yield put({
      type: Ots.GET_SEGMENT_OTS_INFO_SUCCESS,
      segmentOtsInfo: segmentOtsInfo,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Ots.GET_SEGMENT_OTS_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// By Road Segment Id,for ots tool new methodology
function* getSegmentOtsAndReachInfo(action) {
  const { roadId, targetGroupId, startTS, duration, isMediaLit } =
    action.payload;
  try {
    // full ots info of segment
    const segmentOtsAndReachInfo = yield getSegmentOtsAndReachInfoFn(
      roadId,
      targetGroupId,
      startTS,
      duration,
      isMediaLit
    );
    yield put({
      type: Ots.GET_SEGMENT_OTS_AND_REACH_INFO_SUCCESS,
      payload: segmentOtsAndReachInfo,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Ots.GET_SEGMENT_OTS_AND_REACH_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Ots.GET_SEGMENT_OTS_INFO, getSegmentOtsInfo),
    takeLatest(Ots.GET_SEGMENT_OTS_AND_REACH_INFO, getSegmentOtsAndReachInfo),
  ]);
}
