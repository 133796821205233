import { SellerMedia } from "../../constants/action-constants/seller/SellerMediaActionConstants";

export const getSellerMediaByStretchIds = (stretchIds) => ({
  type: SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS,
  payload: { stretchIds },
});

export const resetSellerMediaByStretchIds = () => ({
  type: SellerMedia.RESET_SELLER_MEDIA_BY_STRETCH_IDS,
});

export const getSellerMediaByCity = (cityId, sellerId) => ({
  type: SellerMedia.GET_SELLER_MEDIA_BY_CITY,
  payload: { cityId, sellerId },
});

export const clearSellerMediaByCity = () => ({
  type: SellerMedia.CLEAR_SELLER_MEDIA_BY_CITY,
});

export const updateMetricsForSellerMedia = (sellerId, mediaId, cityId) => ({
  type: SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA,
  payload: { sellerId, mediaId, cityId },
});

export const getSellerMediaInfo = (mediaId, sellerId) => ({
  type: SellerMedia.GET_SELLER_MEDIA_INFO,
  payload: { mediaId, sellerId },
});

export const updateSellerMediaInfo = (mediaId, mediaInfoBean, history) => ({
  type: SellerMedia.UPDATE_SELLER_MEDIA_INFO,
  payload: { mediaId, mediaInfoBean, history },
});

export const addSellerMediaImages = (sellerId, mediaId, files) => ({
  type: SellerMedia.ADD_SELLER_MEDIA_IMAGES,
  payload: { sellerId, mediaId, files },
});

export const removeSellerMediaImage = (mediaId, image) => ({
  type: SellerMedia.REMOVE_SELLER_MEDIA_IMAGE,
  payload: { mediaId, image },
});

export const enableSellerMedia = (mediaId) => ({
  type: SellerMedia.ENABLE_SELLER_MEDIA,
  payload: { mediaId },
});

export const disableSellerMedia = (mediaId) => ({
  type: SellerMedia.DISABLE_SELLER_MEDIA,
  payload: { mediaId },
});

export const uploadSellerInventory = (sellerInventoryBean, history) => ({
  type: SellerMedia.SELLER_INVENTORY_UPLOAD,
  payload: { sellerInventoryBean, history },
});

export const downloadSellerInventoryTemplate = (fileName) => ({
  type: SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE,
  payload: { fileName },
});

// Seller Media Filters
export const setSellerMediaAppliedFilters = (appliedFiltersObj) => ({
  type: SellerMedia.SET_SELLER_MEDIA_FILTERS,
  payload: { appliedFiltersObj },
});

export const downloadSellerMediaPdfReport = (
  sellerId,
  mediaId,
  radius,
  mediaTitle
) => ({
  type: SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT,
  payload: { sellerId, mediaId, radius, mediaTitle },
});
