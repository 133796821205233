// Components
import Spinner from "../../../components/spinner/Spinner";

// Media Report Download
export default function MediaReportDownload({
  loading = false,
  onClick = () => {},
}) {
  // loading
  if (loading) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer my-2"
      onClick={onClick}
      data-html2canvas-ignore="true"
    >
      Download Media PDF Report
    </p>
  );
}
