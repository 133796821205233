/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Apis
import {
  addSellerMediaImagesFn,
  disableSellerMediaFn,
  enableSellerMediaFn,
  getSellerMediaByCityFn,
  getSellerMediaInfoFn,
  getStretchMediaDetails,
  removeSellerMediaImageFn,
  updateMetricsForSellerMediaFn,
  updateSellerMediaInfoFn,
  sellerInventoryUploadFn,
  downloadSellerInventoryTemplateFn,
  downloadSellerMediaPdfReportFn,
} from "../../apis/SellerMediaAPI";

// Constants and Utils
import { SellerMedia } from "../../constants/action-constants/seller/SellerMediaActionConstants";
import { getErrorMessage } from "../../utils/util";
import { getkypTraffic } from "../../apis/CampaignMediaAPI";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { saveFile } from "../../common-utils/file-utils/FileUtils";
import { getSellerId } from "../../utils/SellerUtils";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import {
  FilenameExtensions,
  ReportConstants,
} from "../../constants/GeneralConstants";

export function* getStretchMedia(action) {
  try {
    const { stretchIds } = action.payload;
    const response = yield getStretchMediaDetails(stretchIds);

    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS_SUCCESS,
      payload: { stretchToSellerMedia: response },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Seller media by city
 */
export function* getSellerMediaByCity(action) {
  try {
    const { cityId, sellerId } = action.payload;

    // Api call
    const response = yield getSellerMediaByCityFn(cityId, sellerId);
    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_BY_CITY_SUCCESS,
      payload: { sellerMedia: response.items },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_BY_CITY_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

/**
 *  Update mavin metrics for seller media
 */
export function* updateMetricsForSellerMedia(action) {
  const { sellerId, mediaId, cityId } = action.payload;

  try {
    // Api call
    yield updateMetricsForSellerMediaFn(sellerId, mediaId);

    // To update the media impression
    const response = yield getSellerMediaByCityFn(cityId, sellerId);

    yield put({
      type: SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA_SUCCESS,
      payload: { mediaId, sellerMediaList: response.items },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put({
      type: SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA_FAILURE,
      payload: { error, mediaId },
    });
    toast.error(errorMessage);
  }
}

/**
 *  seller media info
 */
export function* getSellerMediaInfo(action) {
  const { mediaId, sellerId } = action.payload;

  try {
    // Api call
    const sellerMediaInfo = yield getSellerMediaInfoFn(sellerId, mediaId);

    let kypTrafficData = {};

    // Api to get traffic data
    if (sellerMediaInfo.roadSegmentIds?.length > 0) {
      const roadSegmentId = sellerMediaInfo.roadSegmentIds[0];
      kypTrafficData = yield getkypTraffic(roadSegmentId);
    }

    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_INFO_SUCCESS,
      sellerMediaInfo: sellerMediaInfo,
      kypTrafficData: kypTrafficData?.result,
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: SellerMedia.GET_SELLER_MEDIA_INFO_FAILURE,
      payload: error,
    });
  }
}

/**
 *  update-seller media info
 */
export function* updateSellerMediaInfo(action) {
  const { mediaId, mediaInfoBean, history } = action.payload;
  const sellerId = getSellerId();

  try {
    // Api call
    const updatedSellerMediaInfo = yield updateSellerMediaInfoFn(
      sellerId,
      mediaId,
      mediaInfoBean
    );

    // Redirecting to the Seller-Media-Detail-Page
    const url = RedirectTo.sellerMediaDetailPageUrl
      .replace(":sellerId", sellerId)
      .replace(":mediaId", mediaId);
    history.push(constructRedirectPath(url));

    yield put({
      type: SellerMedia.UPDATE_SELLER_MEDIA_INFO_SUCCESS,
      updatedSellerMediaInfo: updatedSellerMediaInfo,
    });
    toast.success("Successfully Updated Media");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.UPDATE_SELLER_MEDIA_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Add-Seller Media Images
export function* addSellerMediaImages(action) {
  const { sellerId, mediaId, files } = action.payload;

  try {
    const sellerMediaFileItems = yield addSellerMediaImagesFn(
      sellerId,
      mediaId,
      files
    );

    yield put({
      type: SellerMedia.ADD_SELLER_MEDIA_IMAGES_SUCCESS,
      sellerMediaFileItems: sellerMediaFileItems,
    });
    toast.success("Successfully Added");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.ADD_SELLER_MEDIA_IMAGES_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Remove-Seller Media Image
export function* removeSellerMediaImage(action) {
  const { mediaId, image } = action.payload;
  const sellerId = getSellerId();

  try {
    const responseMessage = yield removeSellerMediaImageFn(
      sellerId,
      mediaId,
      image
    );

    yield put({
      type: SellerMedia.REMOVE_SELLER_MEDIA_IMAGE_SUCCESS,
      removedImage: image,
    });
    toast.success(responseMessage);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.REMOVE_SELLER_MEDIA_IMAGE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Enable Seller Media
export function* enableSellerMedia(action) {
  const { mediaId } = action.payload;
  const sellerId = getSellerId();

  try {
    // Api call to enable seller media
    const responseMessage = yield enableSellerMediaFn(sellerId, mediaId);

    // to update media status
    yield put({
      type: SellerMedia.UPDATE_SELLER_MEDIA_STATUS,
      payload: { mediaId, isEnabled: true },
    });

    yield put({
      type: SellerMedia.ENABLE_SELLER_MEDIA_SUCCESS,
    });

    toast.success(responseMessage);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.ENABLE_SELLER_MEDIA_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Disable Seller Media
export function* disableSellerMedia(action) {
  const { mediaId } = action.payload;
  const sellerId = getSellerId();

  try {
    // Api call to disable seller media
    const responseMessage = yield disableSellerMediaFn(sellerId, mediaId);

    yield put({
      type: SellerMedia.DISABLE_SELLER_MEDIA_SUCCESS,
    });

    // to update media status
    yield put({
      type: SellerMedia.UPDATE_SELLER_MEDIA_STATUS,
      payload: { mediaId, isEnabled: false },
    });

    toast.success(responseMessage);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.DISABLE_SELLER_MEDIA_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Seller Inventory Upload
export function* sellerInventoryUpload(action) {
  const { sellerInventoryBean, history } = action.payload;

  const sellerId = getSellerId();

  try {
    // Api call to upload seller inventory images and files
    yield sellerInventoryUploadFn(sellerId, sellerInventoryBean);

    yield put({
      type: SellerMedia.SELLER_INVENTORY_UPLOAD_SUCCESS,
      payload: { success: true },
    });

    history.push(constructRedirectPath("/seller/inventory"));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.SELLER_INVENTORY_UPLOAD_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

// Download Seller Inventory Template
export function* downloadSellerInventoryTemplate(action) {
  const { fileName } = action.payload;

  try {
    const response = yield downloadSellerInventoryTemplateFn(fileName);
    saveFile(fileName, response);

    yield put({
      type: SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* downloadSellerMediaPdfReport(action) {
  try {
    const { sellerId, mediaId, radius, mediaTitle } = action.payload;

    const data = yield downloadSellerMediaPdfReportFn(
      sellerId,
      mediaId,
      radius
    );

    // file name
    const pdfFileName = mediaTitle
      ? `${mediaTitle}${FilenameExtensions.pdf}`
      : ReportConstants.MEDIA_PDF_REPORT;

    // save file
    saveFile(pdfFileName, data);

    yield put({
      type: SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export default function* root() {
  yield all([
    takeLatest(SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS, getStretchMedia),
    takeLatest(SellerMedia.GET_SELLER_MEDIA_BY_CITY, getSellerMediaByCity),
    takeLatest(
      SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA,
      updateMetricsForSellerMedia
    ),
    takeLatest(SellerMedia.GET_SELLER_MEDIA_INFO, getSellerMediaInfo),
    takeLatest(SellerMedia.UPDATE_SELLER_MEDIA_INFO, updateSellerMediaInfo),
    takeLatest(SellerMedia.ADD_SELLER_MEDIA_IMAGES, addSellerMediaImages),
    takeLatest(SellerMedia.REMOVE_SELLER_MEDIA_IMAGE, removeSellerMediaImage),
    takeLatest(SellerMedia.ENABLE_SELLER_MEDIA, enableSellerMedia),
    takeLatest(SellerMedia.DISABLE_SELLER_MEDIA, disableSellerMedia),
    takeLatest(SellerMedia.SELLER_INVENTORY_UPLOAD, sellerInventoryUpload),
    takeLatest(
      SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE,
      downloadSellerInventoryTemplate
    ),
    takeLatest(
      SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT,
      downloadSellerMediaPdfReport
    ),
  ]);
}
