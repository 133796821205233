import { Ots } from "../../constants/action-constants/mavin-tools/OtsActionConstants";

// for "OTS" tool
export const getSegmentOtsInfo = (id, isOneWaySegment, lanesCount, type) => ({
  type: Ots.GET_SEGMENT_OTS_INFO,
  payload: { id, isOneWaySegment, lanesCount, type },
});

export const clearSegmentOtsInfo = () => ({
  type: Ots.CLEAR_SEGMENT_OTS_INFO,
});

// for "OTS" tool new methodology
export const getSegmentOtsAndReachInfo = (
  roadId,
  targetGroupId,
  startTS,
  duration,
  isMediaLit
) => ({
  type: Ots.GET_SEGMENT_OTS_AND_REACH_INFO,
  payload: { roadId, targetGroupId, startTS, duration, isMediaLit },
});

export const clearSegmentOtsAndReachInfo = () => ({
  type: Ots.CLEAR_SEGMENT_OTS_AND_REACH_INFO,
});
