import { useEffect } from "react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker, Polyline } from "react-leaflet";

// Actions
import {
  addSegment,
  getClosestRoadSegments,
  removeSegment,
  resetRoadStretches,
} from "../../../actions/org/OrgRoadStretchActions";

// Constants and Utils
import {
  getCoordinates,
  isValidCoordinate,
} from "../../../common-utils/geo-util/coordinatesUtil";
import { India } from "../../../constants/GeneralConstants";
import {
  SelectedRouteStyle,
  UnSelectedRouteStyle,
} from "../../../constants/CssConstants";
import { getMapZoomLevel } from "../../mavin-tools/ToolsUtils";

// Components import
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapIcon from "../../../components/map/map-icon/MapIcon";
import PinDropLocationAndZoom from "../../../components/map/pin-drop-select/PinDropLocationAndZoom";
import PlacesAutocomplete from "../../../components/places-autocomplete/PlacesAutocomplete";
import Spinner from "../../../components/spinner/Spinner";

// Page Components
// ----------------------------------------------------------------------------
function LocationSearch({ setLocationStr, locationStr }) {
  const closestRoadSegmentLoading = useSelector(
    (state) => state.orgRoadStretch.closestRoadSegmentLoading
  );

  return (
    <div className="autocomplete-map p-2 w-50">
      <PlacesAutocomplete
        className="shadow-none rounded-lg"
        placeholder="Search places (autocomplete)"
        setLocationStr={setLocationStr}
        locationStr={locationStr}
      />
      {closestRoadSegmentLoading && (
        <Spinner className="spinner-inside-search spinner-border-sm" />
      )}
    </div>
  );
}

// for road-segment
function SegmentLine({ segment }) {
  const dispatch = useDispatch();

  const segmentId = segment.id;
  const segmentPoints = segment.trace || [];

  // Start and end Coordinates strings of the segment
  const startCoordinate = segmentPoints[0].join();
  const endCoordinate = segmentPoints[segmentPoints.length - 1].join();

  const selectedSegmentsMap = useSelector(
    (state) => state.orgRoadStretch.selectedSegments
  );

  function isSegmentSelected() {
    const segmentsObjects = selectedSegmentsMap[startCoordinate] || {};
    return segmentsObjects[segmentId];
  }

  function isSelectedSegmentBetweenTwoSelectedSegments() {
    // If a selected segment is between two selected segments
    // then below logic holds true
    const segmentsForStartCoodinate =
      selectedSegmentsMap[startCoordinate] || {};
    const segmentCountForStartCoordinate = Object.keys(
      segmentsForStartCoodinate
    ).length;

    const segmentsForEndCoordinate = selectedSegmentsMap[endCoordinate] || {};
    const segmentCountForEndCoordinate = Object.keys(
      segmentsForEndCoordinate
    ).length;

    return (
      segmentCountForStartCoordinate > 1 && segmentCountForEndCoordinate > 1
    );
  }

  const isSegmentBetweenTwoSelectedSegments =
    isSelectedSegmentBetweenTwoSelectedSegments();
  const segmentSelected = isSegmentSelected();

  function onPolylineClick(e) {
    if (!segmentSelected) {
      dispatch(addSegment(segment));
      dispatch(getClosestRoadSegments(startCoordinate, true));
    }

    if (!isSegmentBetweenTwoSelectedSegments && segmentSelected) {
      dispatch(removeSegment(segment));
    }
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  // Style for Polyline
  const styles = segmentSelected ? SelectedRouteStyle : UnSelectedRouteStyle;

  return (
    <Polyline
      pathOptions={styles}
      positions={segmentPoints}
      eventHandlers={{ click: onPolylineClick }}
      clica
    />
  );
}

/**
 * Page-Section
 */
function RoadStretchCreateMapView({ coordinate, setCoordinate }) {
  const dispatch = useDispatch();
  // State
  const [locationStr, setLocationStr] = useState("");
  const [mapZoom, setMapZoom] = useState(null);

  // Fetch the roadSegments from the reducer
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // clearing the states if NO locationStr
  useEffect(() => {
    if (!locationStr) {
      setCoordinate([]);
      dispatch(resetRoadStretches());
    }
  }, [locationStr]);

  // Center
  const mapCenter = coordinate.length ? coordinate : India.mapCenter;
  const zoom = getMapZoomLevel(coordinate, mapZoom); // zoom

  // map-point-marker
  const marker = { type: "selected-poi" };
  const icon = new MapIcon({ type: marker.type });

  // Functions
  // This call-back function is used to capture the PinDrop Location
  // And initiate the other functions accordingly
  function getCoordinatesFromPinDrop(locationData) {
    const coordinateString = `${locationData.lat},${locationData.lng}`;
    setLocationStr(coordinateString);
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(getClosestRoadSegments(coordinateString, true));
  }

  // This call-back function is used to capture the AutoComplete Location
  // And initiate the other fuctions accordingly..
  function getCoordinatesFromAutoComplete(locationStr) {
    setLocationStr(locationStr);
    if (isValidCoordinate(locationStr)) {
      setCoordinate(getCoordinates(locationStr));
      dispatch(getClosestRoadSegments(locationStr, true));
    }
  }

  return (
    <div className="map-layout-right">
      <div className="org-map-cont leaflet-container">
        {/* Search by Location and LatLng Input Section */}
        <LocationSearch
          setLocationStr={getCoordinatesFromAutoComplete}
          locationStr={locationStr}
        />

        {/* Map */}
        <LLMap center={mapCenter} zoom={zoom}>
          {/* PinDrop Marker */}
          {coordinate.length > 0 && (
            <Marker position={coordinate} icon={icon} />
          )}

          {/* PinDropLocationAndZoom Component */}
          <PinDropLocationAndZoom
            onLocationSelect={getCoordinatesFromPinDrop}
            mapZoom={mapZoom}
            setMapZoom={setMapZoom}
          />

          {/* Segments Lines */}
          {roadSegments.length > 0 &&
            roadSegments.map((eachSegment, i) => (
              <SegmentLine key={i} segment={eachSegment} />
            ))}
        </LLMap>
      </div>
    </div>
  );
}

export default RoadStretchCreateMapView;
