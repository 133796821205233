export const getOrgAllUserListUrl = "/api/v3/org/users";
export const getOrgUserByIdUrl = "/api/v3/org/user/:userId";
export const disableOrgUserUrl = "/api/v3/org/user/:userId/disable";
export const enableOrgUserUrl = "/api/v3/org/user/:userId/enable";
export const createOrgUserUrl = "/api/v3/org/user";
export const updateOrgUserUrl = "/api/v3/org/user/:userId";
//  TODO ::Remove this once update api is ready
// export const updateOrgUserRoleUrl = "/api/v3/org/user/:userId/roles";
export const getOrgUserStatsUrl = "/api/v3/org/user_stats";
export const updateOrgUserPriceSettingsUrl =
  "/api/v3/user/:userId/price_settings";
