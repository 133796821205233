import React from "react";
import { useSelector } from "react-redux";
import { Circle, Marker, Popup } from "react-leaflet";

// Components
import MapIcon from "../map-icon/MapIcon";

// Page FUnctions
function _toMarkers(dataPoints) {
  if (!dataPoints) {
    return [];
  }

  return dataPoints.map((dp) => ({
    id: dp.id,
    type: dp.type,
    markerText: dp.name,
    position: [dp.center.latitude, dp.center.longitude],
  }));
}

// get Poi Markers
export function getPoiMarkers(selectedBrandsMap = {}, selectedBrandData = {}) {
  const markers = Object.keys(selectedBrandsMap).reduce((acc, brandId) => {
    const brandInfo = selectedBrandsMap[brandId];
    const isBrandSelected = brandInfo.selected;

    if (!brandInfo || !isBrandSelected) {
      return acc;
    }

    const brandData = selectedBrandData[brandId];
    acc = acc.concat(_toMarkers(brandData));
    return acc;
  }, []);

  return markers;
}

/**
 * POI Markers
 */
export default function PoiMarkers() {
  // Selected Brands Map
  const selectedBrandsMap = useSelector((state) => state.poiSelection.brandIds);

  // Selected Brand Data
  const selectedBrandData = useSelector(
    (state) => state.poiSelection.brandData
  );

  // Poi Markers
  const poiMarkers = getPoiMarkers(selectedBrandsMap, selectedBrandData);

  // to show/hide ==> poi-influence-marker
  const showInfluenceCircle = useSelector(
    (state) => state.poiSelection.showInfluenceCircle
  );

  const influenceCircleRadius = useSelector(
    (state) => state.poiSelection.influenceCircleRadius
  );

  if (poiMarkers.length <= 0) {
    return null;
  }

  return (
    <>
      {poiMarkers.map((marker, i) => {
        const icon = new MapIcon({ type: marker.type });
        return (
          <Marker key={i} position={marker.position} icon={icon}>
            <Popup>
              <b>{marker.markerText}</b>
            </Popup>
          </Marker>
        );
      })}

      {/* influence circle markers */}
      {showInfluenceCircle &&
        poiMarkers.map((marker, i) => (
          <Circle
            key={i}
            center={marker.position}
            pathOptions={{ color: "grey", fillColor: "red" }}
            radius={influenceCircleRadius}
          />
        ))}
    </>
  );
}
